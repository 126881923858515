import http from '@/http-common'

const getAll = async () => {
  return await http
    .get('/service')
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const create = async data => {
  return await http
    .post('/service', data)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const update = async (id, data) => {
  return await http
    .put(`/service/${id}`, data)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const requestCreate = async data => {
  return await http
    .post('/service/request', data)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const requestGetAll = async () => {
  return await http
    .get('/service/request')
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const requestById = async id => {
  return await http
    .get(`/service/request/${id}`)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}
const answerRequest = async (id, data) => {
  return await http
    .put(`/service/request/${id}/answer`, data)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}
const startRequest = async (id) => {
  return await http
    .put(`/service/request/${id}/start`)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const addMessage = async (id, data) => {
  return await http
    .put(`/service/request/${id}/message`, data)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}
const messageUpload = async (id, messageId,file) => {
  var formData = new FormData()
  formData.append('file', file)
  return await http
    .put(`/service/request/${id}/message/${messageId}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const finishRequest = async (id) => {
  return await http
    .put(`/service/request/${id}/finish`)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

export default {
  getAll,
  create,
  update,
  request: {
    create: requestCreate,
    getAll: requestGetAll,
    byId: requestById,
    answer: answerRequest,
    start: startRequest,
    message: addMessage,
    messageUpload: messageUpload,
    finish: finishRequest,

  }
}
