<template>
  <div class="d-flex flex-column gap-4">
    <v-alert v-if="error" type="error">
      {{ error }}
    </v-alert>
    <QuotationRequest
      v-if="hasPermission([1073741824, 2147483648])"
      :loading="loading"
      :quotations="quotations"
      @refresh="getQuotations"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import QuotationRequest from '@/components/quotation/QuotationRequest.vue'
import QUOTATION from '@/services/quotation'

export default {
  name: 'Budget',
  data() {
    return {
      quotations: [],
      loading: false,
      error: null,
    }
  },
  methods: {
    async getQuotations() {
      try {
        this.loading = true
        this.error = null
        const { quotations } = await QUOTATION.getAll()
        this.quotations = quotations
      } catch (error) {
        this.error = error.message || error
      } finally {
        this.loading = false
      }
    },
  },
  mounted() {
    this.getQuotations()
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
  },
  components: { QuotationRequest },
}
</script>
