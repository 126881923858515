<template>
  <v-dialog v-model="dialog" max-width="500px" scrollable hide-overlay>
    <v-card>
      <v-card-title>
        <span class="text-h5">Nova Solicitação de Orçamento</span>
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-form v-model="valid" @submit.prevent="">
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="service.serviceId"
                  :items="serviceList"
                  label="Serviço"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  required
                  hide-details
                  :rules="[v => !!v || 'Campo obrigatório']"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="service.responsible"
                  :rules="[v => !!v || 'Campo obrigatório']"
                  :items="responsibleList"
                  label="Prestadores de Serviço"
                  hide-details
                  outlined
                  multiple
                  required
                  dense
                >
                  <template v-slot:selection="{ item }">
                    <v-chip v-if="item" color="primary" dark label small>
                      {{ item.text }}
                      <small class="pl-2">{{ item.role }}</small>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.text }} <small class="pl-2">{{ item.role }}</small>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="service.deadline"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="service.deadline | date('DD/MM/YYYY')"
                      label="Prazo"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      required
                      hide-details
                      :rules="[v => !!v || 'Campo obrigatório']"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="tempDeadline" locale="pt-BR" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">Cancelar</v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(tempDeadline)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  v-model="service.description"
                  label="Descrição"
                  outlined
                  dense
                  required
                  :rules="[v => !!v || 'Campo obrigatório']"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="px-8 pb-8">
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="loading" class="mr-2 mt-2" outlined @click="close">Cancelar</v-btn>
        <v-btn color="primary" :disabled="!valid" :loading="loading" class="mt-2" @click="save">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SERVICES from '@/services/services'
import QUOTATION from '@/services/quotation'
import USERS from '@/services/user'

import moment from 'moment'
const defaultData = () => ({
  serviceId: null,
  description: null,
  deadline: null,

  responsible: [],
})

export default {
  components: {},
  data() {
    return {
      menu: false,
      loading: false,
      dialog: false,
      valid: false,
      service: defaultData(),
      serviceList: [],
      responsibleList: [],
      tempDeadline: null,
    }
  },
  methods: {
    async getServiceList() {
      try {
        const { services } = await SERVICES.getAll()
        this.serviceList = services.map(service => ({
          text: service.name,
          value: service.id,
        }))
      } catch (error) {
        console.log(error)
      }
    },
    async getResponsibleList() {
      try {
        const { users } = await USERS.getAll()
        const requiredPermission = BigInt(4294967296)
        this.responsibleList = users
          .filter(({ permission }) => (BigInt(permission) & requiredPermission) === requiredPermission)
          .map(user => ({
            text: user.name,
            role: user.role,
            value: user.id,
          }))
      } catch (error) {
        console.log(error)
      }
    },
    async save() {
      try {
        this.loading = true
        await QUOTATION.create({
          ...this.service,
          deadline: moment(this.service.deadline).toISOString(),
        })
        this.$emit('success')
        this.close()
      } catch (error) {
        console.log(error)
        this.$toast.fire({
          icon: 'error',
          title: error.message || 'Erro ao salvar imóvel',
        })
        this.$emit('error', error)
      } finally {
        this.loading = false
      }
    },
    open(data) {
      this.dialog = true
      this.service = Object.assign(defaultData(), data)
      this.tempDeadline = null
    },
    close() {
      this.loading = false
      this.dialog = false
      this.service = defaultData()
    },
  },
  mounted() {
    this.getServiceList()
    this.getResponsibleList()
  },
  created() {
    this.$root.$on('service-request', this.open)
  },
}
</script>

<style></style>
