import http from '@/http-common'
import md5 from 'md5'

const getAll = async () => {
  return await http
    .get('/user')
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const create = async data => {
  data.password = md5(data.password)
  return await http
    .post('/user', data)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const update = async data => {
  var id = data.id
  delete data.id

  data.password = !!data.password ? md5(data.password) : undefined

  return await http
    .put(`/user/${id}`, data)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}
const destroy = async id => {

  return await http
    .delete(`/user/${id}`)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

export default {
  getAll,
  create,
  update,
  destroy
}
