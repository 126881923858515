import http from '@/http-common'

const getAll = async () => {
  return await http
    .get('/quotation')
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const create = async data => {
  return await http
    .post('/quotation', data)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const byId = async id => {
  return await http
    .get(`/quotation/${id}`)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const answer = async (id, data) => {
  return await http
    .post(`/quotation/${id}/answer`, data)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

export default {
  getAll,
  create,
  answer,
  byId,
}
